import React from 'react';
import HeroImage from '../HeroImage/HeroImage';
import heroImgSrc from '../../assets/resources-hero.png';
import { faLaptop } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TagManager from 'react-gtm-module';

function ResourcesPage() {
  // Google Tag Manager custom data layer
  const tagManagerArgs = {
    dataLayer: {
      application: 'COVID Host Genome Survey',
      page: 'Resources',
    },
    dataLayerName: 'Pages',
  };

  TagManager.dataLayer(tagManagerArgs);

  return (
    <div className="resources-page-container flex-12-of-12">
      <HeroImage
        imgSrc={heroImgSrc}
        imgAlt="COVID-19 Resources"
        imgCredit=""
      />
      <section className="feature-container">
        <article className="feature-section flex-container">
          <div className="feature-content flex-md-9-of-12">
            <h2>COVID-19 Related Resources</h2>
            <table>
              <tbody>
                <tr>
                  <td>Stanford Resources</td>
                  <td>
                    <div><a className="su-link su-link--external" href="https://www.med.stanford.edu/covid19.html" target="_blank" rel="noopener noreferrer">https://www.med.stanford.edu/covid19.html</a></div>
                    <div><a className="su-link su-link--external" href="https://stanfordhealthcare.org/discover/covid-19-resource-center.html" target="_blank" rel="noopener noreferrer">https://stanfordhealthcare.org/discover/covid-19-resource-center.html</a></div>
                    <div><a className="su-link su-link--external" href="https://www.stanfordchildrens.org/en/about/news/coronavirus" target="_blank" rel="noopener noreferrer">https://www.stanfordchildrens.org/en/about/news/coronavirus</a></div>
                  </td>
                </tr>
                <tr>
                  <td>California Department of Public Health</td>
                  <td>
                    <div><a className="su-link su-link--external" href="https://www.cdph.ca.gov/Programs/CID/DCDC/Pages/Immunization/ncov2019.aspx" target="_blank" rel="noopener noreferrer">https://www.cdph.ca.gov/Programs/CID/DCDC/Pages/Immunization/ncov2019.aspx</a></div>
                    <div><strong>En Español:</strong> Para obtener información en español, visite nuestra página del <a className="su-link su-link--external" href="https://www.cdph.ca.gov/Programs/CID/DCDC/Pages/Immunization/nCoV2019_Spanish.aspx" target="_blank" rel="noopener noreferrer">Coronavirus 2019 (COVID-19)</a>.</div>
                  </td>
                </tr>
                <tr>
                  <td>CDC: Centers for Disease Control and Prevention</td>
                  <td>
                    <div><a className="su-link su-link--external" href="https://www.cdc.gov/coronavirus/2019-ncov/index.html" target="_blank" rel="noopener noreferrer">https://www.cdc.gov/coronavirus/2019-ncov/index.html</a></div>
                  </td>
                </tr>
                <tr>
                  <td>NIH: National Institutes for Health</td>
                  <td>
                    <div><a className="su-link su-link--external" href="https://www.nih.gov/health-information/coronavirus" target="_blank" rel="noopener noreferrer">https://www.nih.gov/health-information/coronavirus</a></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="feature-image feature-image__right flex-md-3-of-12">
            <FontAwesomeIcon icon={faLaptop} />
          </div>
        </article>
      </section>
    </div>
  );
}

export default ResourcesPage;
