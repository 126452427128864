import React from 'react';
import HeroImage from '../HeroImage/HeroImage';
import heroImgSrc from '../../assets/aboutus-hero.png';
import contributors, { labs } from './Contributors';
import { faUsers, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TagManager from 'react-gtm-module';

function AboutPage() {
  // Google Tag Manager custom data layer
  const tagManagerArgs = {
    dataLayer: {
      application: 'COVID Host Genome Survey',
      page: 'About Us',
    },
    dataLayerName: 'Pages',
  };

  TagManager.dataLayer(tagManagerArgs);

  // Renders individual contributor
  function contributor(person) {
    return (
      <article key={person.name} className="contributor flex-md-3-of-12">
        <figure className="su-media su-media--image">
          <div className="su-media__wrapper su-aspect-ratio">
            {person.photo ? (
              <img src={person.photo} alt={person.name} />
            ) : (
              <FontAwesomeIcon icon={faUser} />
            )}
          </div>
        </figure>
        <section className="su-card__contents">
          <h3>{person.name}</h3>
        </section>
      </article>
    );
  }
  const renderContributor = contributors
    .map(item => contributor(item));

  // Renders individual lab
  function lab(person) {
    return (
      <article key={person.name} className="contributor flex-md-3-of-12">
        <figure className="su-media su-media--image">
          <div className="su-media__wrapper su-aspect-ratio">
            <img src={person.photo} alt={person.name} />
          </div>
        </figure>
        <section className="su-card__contents">
          <a href={person.url} className="su-link"><h3>{person.name}</h3></a>
        </section>
      </article>
    );
  }
  const renderLab = labs.map(item => lab(item));

  return (
    <div className="aboutus-page-container flex-12-of-12">
      <HeroImage
        imgSrc={heroImgSrc}
        imgAlt="About Us"
        imgCredit="Linda A. Cicero / Stanford News Service"
        showCTA={false}
      />
      <section className="feature-container">
        <article className="feature-section flex-container">
          <div className="feature-content flex-md-9-of-12">
            <h2>About Us</h2>
            <p>
              We are a group of doctors, researchers, and scientists passionate about expanding our understanding
              of human disease to improve patient care. Our team consists of specialists in genetics, genomics,
              cardiovascular medicine, bioinformatics, and clinical research. Individuals who have contributed
              to this project include:
            </p>
            <div className="contributors-list">
              {renderContributor}
            </div>
            <p className="lab-intro">
              If you are interested in learning more about our team and other research we do, please visit:
            </p>
            <div className="labs-list">
              {renderLab}
            </div>
          </div>
          <div className="feature-image feature-image__right flex-md-3-of-12">
            <FontAwesomeIcon icon={faUsers} />
          </div>
        </article>
      </section>
    </div>
  );
}

export default AboutPage;
