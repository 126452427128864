import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function  Header() {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <section className="su-masthead su-masthead--center flex-12-of-12">
      <a href="#main-content" className="su-skiplinks ">Skip to main content</a>
      <div className="su-brand-bar ">
        <div className="su-brand-bar__container">
          <a className="su-brand-bar__logo" href="https://stanford.edu">Stanford University</a>
        </div>
      </div>
      <section>
        <div className="su-lockup su-lockup--option-d">
          <a href="/">
            <div className="su-lockup__cell1">
              <div className="su-lockup__wordmark-wrapper">
                <span className="su-lockup__wordmark">Stanford</span>
              </div>
            </div>
            <div className="su-lockup__cell2">
              <span className="su-lockup__line1">COVID-19</span>
              <span className="su-lockup__line3">Host Genome Survey</span>
            </div>
          </a>
        </div>
        <div className="su-site-search " role="search">
          <form action="https://www.stanford.edu/search" method="get" acceptCharset="UTF-8">
            <label className="su-site-search__sr-label" htmlFor="search-field">Search this site</label>
            <input type="text" id="search-field" name="q" className="su-site-search__input" placeholder="Search this site" maxLength="128" />
            <button type="submit" name="search" className="su-site-search__submit su-sr-only-text" aria-label="Search">Submit Search</button>
          </form>
        </div>
        <nav className="su-main-nav no-js su-main-nav--mobile-search" aria-label="main menu">
          <button className="su-main-nav__toggle su-main-nav__toggle--right" aria-expanded="false">Menu</button>
          <ul className="su-main-nav__menu-lv1">
            <li className={`su-main-nav__item${currentPath === '/' ? '--current' : ''}`}>
              <Link to="/">Home</Link>
            </li>
            <li className={`su-main-nav__item${currentPath === '/espanol' ? '--current' : ''}`}>
              <Link to="espanol">En Español</Link>
            </li>
            <li className={`su-main-nav__item${currentPath === '/donate' ? '--current' : ''}`}>
              <Link to="/donate">Donate</Link>
            </li>
            <li className={`su-main-nav__item${currentPath === '/resources' ? '--current' : ''}`}>
              <Link to="/resources">Resources</Link>
            </li>
            <li className={`su-main-nav__item${currentPath === '/aboutus' ? '--current' : ''}`}>
              <Link to="/aboutus">About Us</Link>
            </li>
          </ul>
          <div className="su-site-search " role="search">
            <form action="" method="" acceptCharset="UTF-8">
              <label className="su-site-search__sr-label" htmlFor="search-field">Search this site</label>
              <input type="text" id="search-field" name="search-field" className="su-site-search__input" placeholder="Search this site" maxLength="128" />
              <button type="submit" name="search" className="su-site-search__submit su-sr-only-text" aria-label="Search">Submit Search</button>
            </form>
          </div>
        </nav>
      </section>
    </section>
  )
}

export default Header;
