import React from 'react';
import PropTypes from 'prop-types';
import HeroImage from '../HeroImage/HeroImage';
import heroImgSrc from '../../assets/landing-hero.png';
import Feature from './Feature';

function LandingPage({ language }) {
  return (
    <div className="landing-page-container flex-12-of-12">
      <HeroImage
        imgSrc={heroImgSrc}
        imgAlt="Coronavirus"
        imgCredit="Image by CDC / Alissa Eckert, MSMI; Dan Higgins, MAMS; Hannah A Bullock; Azaibi Tamin"
        showCTA={true}
      />
      <Feature language={language} />
    </div>
  );
}

LandingPage.propTypes = {
  language: PropTypes.string,
};

LandingPage.defaultProps = {
  language: 'english',
};

export default LandingPage;
