import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import gtag from 'ga-gtag';

function HeroImage({ imgSrc, imgAlt, imgCredit, showCTA }) {
  const location = useLocation();
  const currentPath = location.pathname;
  let ctaBtnContentSecondary, ctaBtnContentPrimary;

  if (showCTA && currentPath === '/') {
    ctaBtnContentSecondary = 'Team up with Stanford to advance our understanding of COVID-19.';
    ctaBtnContentPrimary = 'Take the survey';
  }

  if (showCTA && currentPath === '/espanol') {
    ctaBtnContentSecondary = 'Únase al equipo de investigadores de Stanford para avanzar nuestro entidimiento sobre COVID-19.';
    ctaBtnContentPrimary = 'Haga la encuesta';
  }

  function handleClick() {
    if (process.env.NODE_ENV === 'production') {
      gtag('event', currentPath === '/espanol' ? 'spanish-hero-button-click' : 'english-hero-button-click');
    }
  }

  return (
    <div className="hero-container su-hero su-hero--caption">
      {showCTA ? (
        <a href="https://stanforduniversity.qualtrics.com/jfe/form/SV_0U7zfy67nIQnkhL"
          onClick={handleClick.bind(this)}
          className="su-cta su-cta--button-center">
          <div className="su-hero__media">  
            <img src={imgSrc} alt={imgAlt} className="su-cta__img" />
            <h2 className="su-cta__button">
              <span className="cta-btn-content-container">
                <span className="cta-btn-content-secondary">
                  {ctaBtnContentSecondary}
                </span>
                <span className="cta-btn-content-primary">
                  <span>{ctaBtnContentPrimary}</span>
                  <FontAwesomeIcon icon={faChevronRight} />
                </span>
              </span>
            </h2>
          </div>
        </a>
      ) : (
        <div className="su-hero__media">
          <img src={imgSrc} alt={imgAlt} />
        </div>
      )}
      <div className="su-hero__content">
        <div className="su-hero__content-inner">
          <p>{imgCredit}</p>
        </div>
      </div>
    </div>
  );
}

HeroImage.propTypes = {
  imgSrc: PropTypes.string,
  imgAlt: PropTypes.string,
  imgCredit: PropTypes.string,
  showCTA: PropTypes.bool,
};

HeroImage.defaultProps = {
  imgSrc: '',
  imgAlt: '',
  imgCredit: '',
  showCTA: false,
};

export default HeroImage;
