import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import LandingPage from './Components/LandingPage/LandingPage';
import DonatePage from './Components/DonatePage/DonatePage';
import ResourcesPage from './Components/ResourcesPage/ResourcesPage';
import AboutPage from './Components/AboutPage/AboutPage';

function App() {
  return (
    <Router>
      <div className="App layout">
        <header className="flex-container flex-container--row-gap">
          <Header />
        </header>
        <section className="flex-container flex-container--row-gap">
          <Switch>
            <Route path="/" exact render={() => <LandingPage language="english" />} />
            <Route path="/espanol" render={() => <LandingPage language="spanish" />} />
            <Route path="/donate" render={() => <DonatePage />} />
            <Route path="/resources" render={() => <ResourcesPage />} />
            <Route path="/aboutus" render={() => <AboutPage />} />
          </Switch>
        </section>
        <footer className="flex-container flex-container--row-gap">
          <Footer />
        </footer>
      </div>
    </Router>
  );
}

export default App;
