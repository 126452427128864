import React from 'react';
import HeroImage from '../HeroImage/HeroImage';
import heroImgSrc from '../../assets/donate-hero.png';
import { faGift } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TagManager from 'react-gtm-module';

function DonatePage() {
  // Google Tag Manager custom data layer
  const tagManagerArgs = {
    dataLayer: {
      application: 'COVID Host Genome Survey',
      page: 'Donate',
    },
    dataLayerName: 'Pages',
  };

  TagManager.dataLayer(tagManagerArgs);

  return (
    <div className="donate-page-container flex-12-of-12">
      <HeroImage
        imgSrc={heroImgSrc}
        imgAlt="Donate to Stanford University"
        imgCredit="Linda A. Cicero / Stanford News Service"
        showCTA={false}
      />
      <section className="feature-container">
        <article className="feature-section flex-container">
          <div className="feature-content flex-md-9-of-12">
            <h2>Support Our Research</h2>
            <p>
              If you would like to support our research through a donation, please visit
              {' '}
              <a className="su-link" href="https://giving.stanford.edu/goto/medgift">https://giving.stanford.edu/goto/medgift</a>
              . On the Gift Details page, please indicate that you would like to support
              "Stanford Medicine", then "Stanford Medicine (other)" and then specify
              "Support COVID-omics Research" in the Special Instructions/Other Designation
              text box.
            </p>
            <p>
              For any questions, please contact Terra Coakley by email at
              {' '}
              <a className="su-link" href="mailto:tcoakley@stanford.edu">tcoakley@stanford.edu</a>
              {' '}
              or by mail at
              {' '}
              <strong>ATTN: Terra Coakley, COVID-Omics Research Fund, Falk CVRC, 300 Pasteur Drive, Stanford, CA 94305.</strong>
            </p>
            <p>We appreciate your support!</p>
          </div>
          <div className="feature-image feature-image__right flex-md-3-of-12">
            <FontAwesomeIcon icon={faGift} />
          </div>
        </article>
      </section>
    </div>
  );
}

export default DonatePage;
