import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import gtag, { install } from 'ga-gtag';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './main.css';

const tagManagerArgs = {
  gtmId: 'GTM-56PTDM7',
  dataLayerName: 'Pages',
};

if (process.env.NODE_ENV === 'production') {
  install('UA-174012658-1');
  gtag('config', 'UA-174012658-1', {'environment': process.env.NODE_ENV});
  TagManager.initialize(tagManagerArgs);
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
