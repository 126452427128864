import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { faViruses } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TagManager from 'react-gtm-module';
import gtag from 'ga-gtag';

function Feature({ language }) {
  // Google Tag Manager custom data layer
  const tagManagerArgs = {
    dataLayer: {
      application: 'COVID Host Genome Survey',
      page: language === 'spanish' ? 'Spanish' : 'Landing',
    },
    dataLayerName: 'Pages',
  };

  TagManager.dataLayer(tagManagerArgs);

  function handleClick(route) {
    if (process.env.NODE_ENV === 'production') {
      gtag('event', language === 'spanish' ? 'spanish-secondary-button-click' : 'english-secondary-button-click');
    }
    window.location=route;
  }

  if (language === 'spanish') {
    return (
      <section className="feature-container">
        <article className="feature-section flex-container">
          <div className="feature-content feature-content__spanish flex-md-8-of-12">
            <h2>
              Encuesta de Stanford sobre el Genoma del Huésped de COVID-19
            </h2>
            <p>
              Únase al equipo de los investigadores de Stanford para avanzar en nuestro entedimiento sobre COVID-19. Algunas personas desarrollan síntomas más severos que otras cuando están infectadas con <strong>SARS-CoV-2</strong>. Nosotros queremos determinar si una de las razones para esto es genética.
            </p>
            <p>
              Usted está invitado a participar en un estudio de investigación que busca identificar si hay una conexión entre la severidad de la enfermedad de COVID-19 y algunos marcadores genéticos. Si usted tiene acceso a su información genética y desea participar en nuestra encuesta de 10-15 minutos de duración, por favor haga clic en el botón de abajo para empezar. Se le pedirá que revise un consentimiento antes de empezar la encuesta. Debe tener 18 años de edad o más para participar en este estudio.
            </p>
            <p>
              La encuesta está disponible
              {' '}
              <Link to="/" className="su-link">en inglés</Link>
              {' '}
              y en español.
            </p>
            <button value="decanter" name="button-survey" className="su-button su-button--big" type="button" onClick={handleClick.bind(this, 'https://stanforduniversity.qualtrics.com/jfe/form/SV_0U7zfy67nIQnkhL')}>Haga la encuesta</button>
          </div>
          <div className="feature-image feature-image__right flex-md-4-of-12">
            <FontAwesomeIcon icon={faViruses} />
          </div>
        </article>
      </section>
    );
  }

  return (
    <section className="feature-container">
      <article className="feature-section flex-container">
        <div className="feature-content flex-md-8-of-12">
          <h2>
            COVID-19 Host Genome Survey
          </h2>
          <p>
            Team up with researchers at Stanford to advance our understanding of COVID-19. Some people develop more severe symptoms than others when they are infected with <strong>SARS-CoV-2</strong>. We would like to determine if one of the reasons for this is genetics.
          </p>
          <p>
            You are invited to participate in a research study aiming to identify if there is a connection between severity of COVID-19 illness and certain genetic markers. If you have access to your genetic data and wish to participate in our 10-15 minute survey, please click the button below to get started. You will be asked to review a consent form before you begin the survey. You must be 18 years of age or older to participate in this study.
          </p>
          <p>
            Survey is available in English and Spanish. La encuesta está disponible en inglés y
            {' '}
            <Link to="/espanol" className="su-link">en español</Link>
            .
          </p>
          <button value="decanter" name="button-survey" className="su-button su-button--big" type="button" onClick={handleClick.bind(this, 'https://stanforduniversity.qualtrics.com/jfe/form/SV_0U7zfy67nIQnkhL')}>Take the survey</button>
        </div>
        <div className="feature-image feature-image__right flex-md-4-of-12">
          <FontAwesomeIcon icon={faViruses} />
        </div>
      </article>
    </section>
  );
}

Feature.propTypes = {
  language: PropTypes.string,
};

Feature.defaultProps = {
  language: 'english',
};

export default Feature;
